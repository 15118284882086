<template>
  <div class="container-top" v-if="value">
    <div class="pc container">
      <div class="subtitle2 main padding-top-44">{{ value.title }}</div>
      <div class="flex-align margin-top-16">
        <span class="body2">{{ selectedTypeName }}</span>
        <span class="body5-medium margin-left-8" :class="{'answered':value.is_answered,'not-answered':!value.is_answered}">
                  {{ value.is_answered?'답변완료':'미답변' }}</span>
      </div>
      <div class="lp-divider-sub5 padding-top-24"></div>
      <div class="body2" style="padding:12px 0 24px 0" v-if="value">
        <div class="padding-bottom-16" v-html="value.content"></div>

        <div class="sub3 body4">{{ datesFormat(value.created_time, 'date_3') }}</div>

        <!-- 답변 -->
        <div class="inquiry-answer-wrapper padding-24 margin-top-36" v-if="value.is_answered">
          <div class="subtitle4">
            <span class="primary">RE</span><span class="subtitle4 main margin-left-8">답변내용</span>
          </div>
          <div class="body2 sub2 margin-top-8">{{ value.answer_content }}</div>
          <div class="body4 sub3 margin-top-16" v-if="value.answer_time">{{ datesFormat(value.answer_time, 'date_3') }}</div>
        </div>

        <div class="lp-divider-gray1 padding-top-40"></div>

        <div class="flex-center margin-top-40">
          <button class="button is-gray body2-medium"
                  style="width:242px"
                  @click="$router.replace('/inquiry');">목록보기</button>
        </div>
      </div>
    </div>

    <div class="mobile">
      <div class="container">
        <div class="h7 main padding-top-24">{{ value.title }}</div>
        <div class="flex-align margin-top-8">
          <span class="body5-medium" :class="{'answered':value.is_answered,'not-answered':!value.is_answered}">
                    {{ value.is_answered?'답변완료':'미답변' }}</span>
          <span class="body6 sub3 margin-left-8">{{ selectedTypeName }}</span>
        </div>
      </div>
      <div class="lp-divider-gray1 margin-top-12"></div>
      <div class="container">
        <div class="padding-top-12 padding-bottom-8 body4 sub2" v-html="value.content"></div>
        <div class="body7 sub4">
          {{ datesFormat(value.created_time, 'date_3') }}</div>
      </div>

      <div class="lp-divider-gray1 padding-top-12"></div>

      <!-- 답변 -->
      <div class="inquiry-answer-wrapper padding-16" v-if="value.is_answered">
        <div>
          <span class="subtitle5 primary">RE</span><span class="subtitle7 main margin-left-8">답변내용</span>
        </div>
        <div class="body4 sub2 margin-top-8">{{ value.answer_content }}</div>
        <div class="body7 sub4 margin-top-16" v-if="value.answer_time">{{ datesFormat(value.answer_time, 'date_3') }}</div>
      </div>

      <div class="flex-center margin-top-28">
        <button class="button is-gray font-600 body5-medium"
                style="width:170px;height:36px"
                @click="$router.replace('/inquiry');">목록보기</button>
      </div>
    </div>
  </div>
</template>
<script>
  import UserAPIMixin from "../../mixins/UserAPIMixin";

  export default {
    name: "PersonalInquiryDetail",
    mixins: [
      UserAPIMixin
    ],
    created() {
      this.getInquiryCategory();
      this.getData();
    },
    data() {
      return {
        catArr: [],
        value: undefined
      }
    },
    computed: {
      selectedTypeName() {
        let name = '';
        let item = this.findItem(this.catArr, 'value', this.value.type_id);
        if(item) {
          name = item.label;
        }
        return name;
      }
    },
    methods: {
      getInquiryCategory() {
        this.$axios.get(`public/inquiry/personal/category`).then(res=>{
          this.catArr = res.data;
        })
      },
      getData() {
        if(this.$route.query.id) {
          this.request.user.get(`inquiry/personal/${this.$route.query.id}`).then(res=> {
            this.value = res.data;
            this.value.type_id = parseInt(this.value.type_id);
          })
        }
      },
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .answered
  .not-answered
    padding 3px 8px
    border-radius 4px

  .answered
    background-color $primary-light2
    color $primary
  .not-answered
    background-color $gray3
    color $sub2
  .inquiry-answer-wrapper
    background-color $gray4
    border-radius 8px
</style>